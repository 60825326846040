import Product from "./Product";
import { product } from "../../../types/products";

function ProductTable({
  products,
  title,
}: {
  products: product[];
  title?: string;
}) {
  return (
    <div className="rounded-sm border border-stroke min-w-[700px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark ">
      <div className="flex flex-col">
        <div className="grid grid-cols-8 rounded-sm bg-amber-50 text-amber-500 ">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              المنتج
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">QR</h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              السعر
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الكمية
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              القسم
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الماركة
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              مميز
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {products?.length > 0 ? (
          products?.map((product, key) => (
            <Product
              title={title}
              product={product}
              key={key}
              length={products.length}
            />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد أي منتجات لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductTable;
