import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { addBranch, editBranch } from "../../../store/Branches/BranchesSlice";
import Video from "../../Video";
import ImagesSlider from "../../Images";
import EmployeeTable from "./EmployeeTable";
import { branch } from "../../../types/branch";
import { toast } from "sonner";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import { AxiosError } from "axios";
import Loader from "../../Loader";

export type FormDataType = {
  id?: number;
  video?: File;
  ar_name?: string;
  en_name?: string;
  ar_description?: string;
  en_description?: string;
  phone?: string;
  email?: string;
  ar_location?: string;
  en_location?: string;
  is_special?: 1 | 0;
  _method?: string;
  [key: string]:
    | undefined
    | File
    | Record<string, File>
    | string
    | boolean
    | number
    | 1
    | 0;
};

type Errors = {
  video?: string[];
  ar_name?: string[];
  en_name?: string[];
  ar_description?: string[];
  en_description?: string[];
  phone?: string[];
  email?: string[];
  ar_location?: string[];
  en_location?: string[];
  images?: { [key: string]: string[] };
  employees?: { [key: string]: string[] };
  is_special?: string[];
};

export type image = {
  id?: number;
  url: string;
  uploaded?: boolean;
  deleted?: boolean;
  file?: File;
};

function AddEditBranch() {
  const { id } = useParams();
  const enabled = id ? true : false;
  const {
    query: { isLoading, isError, error, data },
  } = useFetch<{ data: branch }>(
    `admin/branches/${id}`,
    `branch${id}`,
    enabled
  );
  const branch = data?.data;
  const arEditorRef = useRef<any>(null);
  const enEditorRef = useRef<any>(null);
  const [viewVideo, setViewVideo] = useState<string | undefined>(
    id ? fileURL + branch?.video : ""
  );
  const [viewImages, setViewImages] = useState<image[] | undefined>(
    id ? branch?.images : []
  );
  const [employees, setEmployees] = useState(id ? branch?.employees : []);
  const [errors, setErrors] = useState<Errors>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialData: FormDataType = id
    ? { _method: "put" }
    : { _method: "post", is_special: 0 };
  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    formData,
    mutation,
    viewFiles,
    setImages,
    handleChangeArrayFiles,
  } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data!),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.message);
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = (branch: branch) => {
    toast.success(id ? "تمت تعديل الفرع بنجاح" : "تمت اضافة الفرع بنجاح");
    toast.dismiss("loading");
    navigate("/branches");
    if (id) {
      dispatch(editBranch(branch));
    } else {
      dispatch(addBranch(branch));
    }
    setErrors({});
  };

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم ${id ? "التعديل" : "الإضافة"}`, { id: "loading" });
  }, [mutation]);

  useEffect(() => {
    if (data) {
      setEmployees(data.data?.employees);
      setViewImages(data.data?.images);
      setViewVideo(fileURL + branch?.video);
    }
  }, [data]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData.ar_name]);

  const prepareFormData = () => {
    let newFormData = formData;

    employees
      ?.filter((em) => em.uploaded && !em.deleted)
      ?.forEach((employee, index) => {
        Object.entries(employee).forEach(([key, value]) => {
          if (key !== "uploaded")
            newFormData = {
              ...newFormData,
              [`employees[${index}][${key}]`]: value,
            };
        });
      });
    employees
      ?.filter((em) => !em.uploaded && em.deleted)
      .forEach((employee, index) => {
        newFormData = {
          ...newFormData,
          [`delete_employees[${index}]`]: employee.id,
        };
      });
    viewImages
      ?.filter((im) => im.uploaded === true)
      ?.forEach((image, index) => {
        newFormData = { ...newFormData, [`images[${index}]`]: image.file };
      });

    viewImages
      ?.filter((im) => im.deleted === true)
      .forEach((image, index) => {
        newFormData = {
          ...newFormData,
          [`delete_images[${index}]`]: image.id,
        };
      });
    return newFormData;
  };

  const addAction = (newFormData: FormDataType) => {
    handleSubmit("admin/branches", newFormData);
  };

  const editAction = (newFormData: FormDataType) => {
    handleSubmit(`admin/branches/${id}?_method=put`, newFormData);
  };

  const validate = (formData: FormDataType) => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (keysToCheck.length > 0 || viewFiles.length > 0) {
      isValid = id
        ? keysToCheck.some((key) => !!formData[key]) || viewFiles.length > 0
        : keysToCheck.length >= 12;
    } else isValid = false;

    return isValid;
  };

  const submit = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newFormData = prepareFormData();
    if (!id) {
      if (validate(newFormData)) {
        addAction(newFormData);
      } else toast.error("قم بتعبئة الحقول");
    } else {
      if (validate(newFormData)) {
        editAction(newFormData);
      } else toast.error("قم بالتعديل");
    }
  };

  useEffect(() => {
    if (arEditorRef.current) {
      arEditorRef.current.value = branch ? branch?.ar_description : "";
    }
    if (enEditorRef.current) {
      enEditorRef.current.value = branch ? branch?.ar_description : "";
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-center text-2xl">
        {branch ? "تعديل الفرع: " + branch?.ar_name : "إضافة فرع"}
      </h2>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full"
      >
        <label className="w-fit mx-auto space-y-2 col-span-1 sm:col-span-2">
          <div className="mx-auto w-fit">
            <Video
              viewVideo={viewVideo}
              setViewVideo={setViewVideo}
              setFormData={setFormData}
              name="video"
            />
          </div>

          {errors.video &&
            errors.video.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <div className="w-full mx-auto space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الصور:</p>
          <ImagesSlider
            viewImages={viewImages}
            setViewImages={setViewImages}
            setImages={setImages}
            handleChangeArrayFiles={handleChangeArrayFiles}
          />
          {errors.images &&
            Object.entries(errors.images).map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <label className="w-full space-y-2">
          <p className="font-semibold">الاسم بالعربي:</p>
          <input
            name="ar_name"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب الاسم"
            defaultValue={branch?.ar_name}
            onChange={handleChangeInput}
          />
          {errors.ar_name &&
            errors.ar_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2">
          <p className="font-semibold">الاسم بالانكليزي:</p>
          <input
            name="en_name"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            dir="ltr"
            placeholder="Type Name"
            defaultValue={branch?.en_name}
            onChange={handleChangeInput}
          />
          {errors.en_name &&
            errors.en_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2">
          <p className="font-semibold">الموقع بالعربي:</p>
          <input
            name="ar_location"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب الموقع"
            defaultValue={branch?.ar_location}
            onChange={handleChangeInput}
          />
          {errors.ar_location &&
            errors.ar_location.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2">
          <p className="font-semibold">الموقع بالانكليزي:</p>
          <input
            name="en_location"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            dir="ltr"
            placeholder="Type Location"
            defaultValue={branch?.en_location}
            onChange={handleChangeInput}
          />
          {errors.en_location &&
            errors.en_location.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2">
          <p className="font-semibold">البريد الالكتروني:</p>
          <input
            name="email"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب الايميل"
            defaultValue={branch?.email}
            onChange={handleChangeInput}
          />
          {errors.email &&
            errors.email.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2">
          <p className="font-semibold">رقم الهاتف:</p>
          <input
            name="phone"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            dir="ltr"
            placeholder="اكتب الرقم"
            defaultValue={branch?.phone}
            onChange={handleChangeInput}
          />
          {errors.phone &&
            errors.phone.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الوصف بالعربي:</p>
          <JoditEditor
            ref={arEditorRef}
            value={branch?.ar_description!}
            onChange={(newContent) => {
              setFormData({ ...formData, ar_description: newContent });
            }}
          />
          {errors.ar_description &&
            errors.ar_description.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الوصف بالانكليزي:</p>
          <JoditEditor
            ref={enEditorRef}
            value={branch?.en_description!}
            onChange={(newContent) => {
              setFormData({ ...formData, en_description: newContent });
            }}
          />
          {errors.en_description &&
            errors.en_description.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="flex items-center justify-center col-span-1 sm:col-span-2 gap-4 ">
          <p className="font-semibold">فرع مميز:</p>
          <input
            type="checkbox"
            name="is_special"
            className="relative w-5 h-5"
            defaultChecked={branch?.is_special === 1}
            onChange={(e) =>
              setFormData({
                ...formData,
                is_special: e.currentTarget.checked ? 1 : 0,
              })
            }
          />
          {errors.is_special &&
            errors.is_special.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="relative col-span-1 sm:col-span-2 overflow-x-auto">
          <EmployeeTable employees={employees} setEmployees={setEmployees} />
          {errors.employees &&
            Object.entries(errors.employees).map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <input
          type="submit"
          onClick={(e) => submit(e)}
          value={id ? "تعديل" : "حفظ"}
          className="bg-amber-500 col-span-1 sm:col-span-2 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        />
      </form>
    </div>
  );
}

export default AddEditBranch;
