// LoginPage.tsx
import React, { useEffect, useState } from "react";
import logo from "./Logo.svg";
import { BiHide, BiShow } from "react-icons/bi";

import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { usePOST } from "../../../ApiS";

// import {
//   InputOTP,
//   InputOTPGroup,
//   InputOTPSeparator,
//   InputOTPSlot,
// } from "./components/ui/input-otp"

const Code: React.FC = () => {
  const navigate = useNavigate();
  const { mutation, handleChangeInput, handleSubmit, setFormData, formData } =
    usePOST(
      
      {
        password: "",
        password_confirmation: "",
        verification_code: "",
        email: "",
      },
      () => navigate("/login"),
      () => console.log("error")
    );

  const [biShow, setBiShow] = useState(false);

  // useEffect(() => {
  //   setFormData({
  //     _method: "PUT",
  //   });
  // }, []);

  return (
    <div className="flex-col items-center justify-center h-screen bg-gray-100 mt-2 ">
      {mutation.isLoading ? <Loader /> : ""}
      <div className="bg-white shadow-md rounded-full px-8 pt-6 pb-8 mb-4 w-[50%] max-md:w-[97%]  mx-auto ">
        <img
          src={logo}
          alt=""
          className=" w-full h-[300px] max-md:h-[200px] "
        />
      </div>
      <h1 className="text-xl font-semibold text-center my-2">
        استعادة كلمة المرور
      </h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[55%] max-md:w-[97%]  mx-auto">
        <div className="mb-4 relative">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="password"
          >
            كلمة المرور
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type={!biShow ? "password" : "text"}
            placeholder=" كلمة المرور"
            name="password"
            onChange={handleChangeInput}
          />
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={22}
              className={`absolute right-3 bottom-1 cursor-pointer`}
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={22}
              className={`absolute right-3 bottom-1 cursor-pointer `}
            />
          )}
        </div>
        <div className="mb-4 relative">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="password_confirmation"
          >
             تأكيد كلمة المرور 
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password_confirmation"
            type={!biShow ? "password" : "text"}
            placeholder="تأكيد كلمة المرور"
            name="password_confirmation"
            onChange={handleChangeInput}
          />
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={22}
              className={`absolute right-3 bottom-1 cursor-pointer`}
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={22}
              className={`absolute right-3 bottom-1 cursor-pointer `}
            />
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="verification_code"
          >
            رمز التحقق
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="verification_code"
            type="text"
            placeholder="رمز التحقق"
            name="verification_code"
            onChange={handleChangeInput}
          />
        </div>
        <div className="flex justify-center">
          <button
            className="w-[30%]  bg-primary rounded-xl  text-white font-bold p-2 "
            onClick={() => handleSubmit("admin/reset-password")}
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Code;
