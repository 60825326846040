import React, { useEffect } from "react";
import { useFetch, usePOST } from "../../../../ApiS";
import { toast } from "sonner";
import Loader from "../../../Loader";

type FormDataType = {
  "contact_us-email"?: string;
  "contact_us-headline-ar"?: string;
  "contact_us-headline-en"?: string;
  "contact_us-phone1"?: string;
  "contact_us-phone2"?: string;
};

interface dataType {
  footer: {
    about: {ar: string, en: string}
    rights: {ar: string, en: string}
    facebook: string;
    instagram: string;
    youtube: string;
    telegram: string;
    whatsapp: string;
  };
}

export default function Footer() {
  const { query: {data, isLoading, isError, error} } = useFetch<dataType>(
    "admin/infos",
    "infos"
  );
  const initialData: FormDataType = {};
  const { handleSubmit, handleChangeInput, formData, mutation, setFormData } = usePOST(
    initialData,
    () => successAction(),
    (error) => errorAction(error)
  );

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم التعديل`, { id: "loading" });
  }, [mutation.isLoading]);

  const successAction = () => {
    toast.success("تم التعديل");
    toast.dismiss("loading");
    setFormData({});
  };

  const errorAction = (error: Error) => {
    toast.error(error.message);
    toast.dismiss("loading");
  };

  const submit = () => {
    handleSubmit("admin/infos/update");
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">تذبيل الصفحة</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <label className="w-full space-y-2">
          <p className="font-semibold">الوصف بالعربي:</p>
          <input
            defaultValue={data?.footer?.about.ar}
            name="footer-about-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الوصف بالعربي"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الوصف بالانكليزي:</p>
          <input
            defaultValue={data?.footer?.about.en}
            name="footer-about-en"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الوصف بالانكليزي"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الحقوق بالعربي:</p>
          <input
            defaultValue={data?.footer?.rights?.ar}
            name="footer-rights-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الحقوق بالعربي"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الحقوق بالانكليزي:</p>
          <input
            defaultValue={data?.footer?.rights?.en}
            name="footer-rights-en"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الحقوق بالانلكيزي"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">رابط الانستغرام:</p>
          <input
            defaultValue={data?.footer?.instagram}
            name="footer-instagram"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرابط"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">رابط الفيسبوك:</p>
          <input
            defaultValue={data?.footer?.facebook}
            name="footer-facebook"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرابط"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">رابط يويتيوب:</p>
          <input
            defaultValue={data?.footer?.youtube}
            name="footer-youtube"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرابط"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">رابط تيليغرام:</p>
          <input
            defaultValue={data?.footer?.telegram}
            name="footer-telegram"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرابط"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">رابط الواتساب:</p>
          <input
            defaultValue={data?.footer?.whatsapp}
            name="footer-whatsapp"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرابط"
            onChange={handleChangeInput}
          />
        </label>
      </div>
      {Object.entries(formData).length > 0 && (
        <button
          onClick={submit}
          className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        >
          حفظ
        </button>
      )}
    </div>
  );
}
